import React from "react";
import { Card } from "react-materialize";

function PaymentsHistoryAnalytics() {
  return (
    <div className="flex gap-1 mt-1">
      <Card className="p-1 hover  metabin-card" style={{ width: "20vw" }}>
        <b>All Payments</b>
        <p>135</p>
      </Card>

      <Card className="p-1 metabin-card" style={{ width: "20vw" }}>
        <b>Total Paid Amounts</b>
        <p>421</p>
      </Card>
    </div>
  );
}

export default PaymentsHistoryAnalytics;
