import React from "react";
import { useSelector } from "react-redux";

function GeneralSettings({editProfile}) {
  const { profile } = useSelector((state) => state.profileStore);

  return (
    <div className=" full-width p-1 " >


      <div className="flex align-center justify-end   mt-1 ">
        <div className="btn-outline " onClick={()=>editProfile(true)}>Edit profile</div>
        
      </div>


      <div className="flex align-center mt-1">
        <span className="material-symbols-outlined primary-text mr-1 ">person</span>
        <p className="capitalize" >{profile.name}</p>
      </div>

      <div className="flex align-center mt-1 ">
        <span className="material-symbols-outlined primary-text mr-1 ">mail</span>
        <p>{profile.email}</p>
      </div>

      <div className="flex align-center mt-1 ">
        <span className="material-symbols-outlined primary-text mr-1 ">call</span>
        <p>+{profile.phoneNumber}</p>
      </div>

      <div className="flex align-center mt-1 ">
        <span className="material-symbols-outlined primary-text mr-1 ">domain</span>
        <p>Infinite Cercle (Cercle X)</p>
      </div>

      <div className="flex align-center mt-1 ">
        <span className="material-symbols-outlined primary-text mr-1 ">
          location_on
        </span>
        <p>
          Mahatma Gandhi Rd, Padma Junction, Kacheripady, Ernakulam, Kerala
          682035
        </p>
      </div>
    </div>
  );
}

export default GeneralSettings;
