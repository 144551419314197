import React from "react";

function InvitationsTable({ invitations }) {
  return (
    <div>
      <table className="striped  ">
        <thead>
          <tr>
            <th className="primary-text">#</th>
            <th className="primary-text">Email</th>
            <th className="primary-text">Phone Number</th>
            <th className="primary-text">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr></tr>
        </tbody>
      </table>

      {(invitations.length === 0 || !invitations) && (
        <div className="flex full-width column align-center justify-center mt-2 mb-2 ">
          <span className="material-symbols-outlined large grey-text ">
            group_add
          </span>
          <p className="grey-text small-text mt-2 ">
            There is no any invitations right now.
          </p>
        </div>
      )}
    </div>
  );
}

export default InvitationsTable;
