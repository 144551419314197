import React from "react";
import { useNavigate } from "react-router-dom";
// import { Card } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import bins from "../../mockData";

function SingleMetaBin({ binDetails }) {
  const navigate = useNavigate();

  return (
    <div className="p-1 metabin-card">
      <div className="">
        <div className="flex space-between full-width">
          <div className="flex align-center ">
            <img
              src="../images/smart-bin.png"
              style={{ width: "60px", height: "60px", objectFit: "contain" }}
              alt="smart bin"
            />

            <div className="flex">
              <div>
                <p>{binDetails.address}</p>
                <p className="small-text  capitalize">{binDetails.id}</p>
                <p className="small-text grey-text capitalize">
                  {binDetails.material} bin
                </p>

                <div className="flex align-center">
                  <div
                    className={
                      // binDetails.status === "active"
                      "green live-bin"
                      // : "red live-bin"
                    }
                    style={{ marginRight: 5 }}
                  />
                  <p
                    className={
                      // binDetails.status === "active"
                      "green-text small-text capitalize"
                      // : "red-text small-text capitalize"
                    }
                  >
                    {/* {binDetails.status} */}
                    active
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-end column ">
            <h5 className="cercle-purple-text">
              <span className="small-text grey-text">Metabin Number: </span>
              {binDetails.number}
            </h5>
          </div>
        </div>

        <div>
          <div className=" full-width justify-end flex ">
            <p className="grey-text small-text ">
              {binDetails.fillLevel}% Filled
            </p>
          </div>
          <div className="full-width progress-line">
            <div className="progress-bg-line"></div>
            <div
              className="progress-bg-line2"
              style={{ width: `${binDetails.fillLevel}%` }}
            ></div>
          </div>
        </div>

        <div className="flex align-center space-between ">
          <p>
            <span className="grey-text small-text ">
              Total Products Collected:
            </span>
            &nbsp;{binDetails.itemsCollected || 0}
          </p>
        </div>

        <div className="flex primary-text align-center justify-center">
          <u
            className="hover  "
            onClick={() => navigate(`/bin-details/${binDetails._id}`)}
          >
            View More
          </u>
        </div>
      </div>
    </div>
  );
}

export default SingleMetaBin;
