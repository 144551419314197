import React, { useState, useEffect } from "react";
import Maps from "../components/maps/Maps";
import SingleBin from "../components/SingleBin";


const bins = [
  {
      "_id": "64a530400f07a8a900230245",
      "address": "AIC RAISE Startup Incubation Center, Rathinam Techzone, Eachanari, Tamil Nadu, India",
      "detailedAddress": "Coimbatore, Tamil Nadu, India",
      "location": { "coordinates": [76.966122, 11.016844] },
      "material": "plastic",
        "status": "active",
        "number": "1",
        "fillLevel": "75",
        "itemsCollected": "743",
        "rating": "5"
  },
  {
      "_id": "a7b630401f07b9b002302123",
      "address": "Innovation Hub, Technopark, Trivandrum, Kerala, India",
      "detailedAddress": "Trivandrum, Kerala, India",
      "location": { "coordinates": [76.880182, 8.524139] },
      "material": "plastic",
        "status": "active",
        "number": "2",
        "fillLevel": "34",
        "itemsCollected": "453",
        "rating": "4"
  },
];

function Map() {
  const [openBinDetails, setOpenBinDetails] = useState({
    binId: null,
    open: false
  });

  useEffect(() => {
    if (bins) {
      setOpenBinDetails({ ...openBinDetails, binId: bins[0]?._id });
    }
  }, [bins]);

  return (
    <>
      <div>
        <div style={{ width: "100%" }}>
          <Maps
            openBinDetails={openBinDetails}
            setOpenBinDetails={setOpenBinDetails}
            metaBinsList={bins} 
          />
        </div>
        <div
          className="ml-4 flex"
          style={{
            height: "100%",
            position: "fixed",
            right: 0,
            top: "6vh",
            overflowY: "auto",
            marginBottom: "2rem"
          }}
        >
          
          <div
            className=" flex align-center  lighten-2 justify-center mt-5 box hover d-depth-2 "
            style={{
              height: "4rem ",
              width: "4rem",
              borderRadius: "8px 0 0 8px "
            }}
            onClick={() =>
              setOpenBinDetails({
                ...openBinDetails,
                open: !openBinDetails.open
              })
            }
          >
            {!openBinDetails.open ? (
              <span
                className="material-symbols-outlined big-icon"
                style={{ color: "#6f2da8" }}
              >
                close
              </span>
            ) : (
              <span
                className="material-symbols-outlined big-icon"
                style={{ color: "#6f2da8" }}
              >
                close
              </span>
            )}
          </div>
         
          {openBinDetails.open && <SingleBin openBinDetails={openBinDetails} binsData={bins} />}
        </div>
      </div>
    </>
  );
}

export default Map;
