export const topBinsData = [
    {
        _id: "BIN1",
        address: "DEL12, Delhi",
        count: 430
    },
    {
        _id: "BIN2",
        address: "BANG68, Bangalore, Karnataka",
        count: 367
    },
    {
        _id: "BIN3",
        address: "COIM4, Coimbatore, Tamil Nadu",
        count: 352
    },
    {
        _id: "BIN4",
        address: "BANG28, Bangalore, Karnataka",
        count: 245
    },
    {
        _id: "BIN5",
        address: "MUM2, Mumbai, Maharashtra",
        count: 230
    }
];
