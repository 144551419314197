import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { socket } from "../../redux/config/socket";
import { toast } from "react-toastify";
import bins from "../../mockData";

function BinDetails({binDetails}) {
  // const binDetails = bins[0];
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });

  useEffect(() => {
    socket.on("product_dropped_dashboard", (data) => {
      if (data.metabin !== binDetails._id) return;
      toast.info(data.msg, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    });

    return () => socket.off("product_dropped_dashboard");
  }, [binDetails._id, binDetails.address]);

  return (
    <div className={isTablet && "mb-1"}>
      <div className="metabin-card p-1 ">
        <div className="flex space-between full-width mt-1">
          <div className="flex align-center ">
            <img
              src="../images/smart-bin.png"
              style={{ width: "80px", height: "80px" }}
              alt="bin"
            />
            <div>
              <span className="flex align-center ">
                <i
                  className="material-symbols-outlined cercle-purple-text"
                  style={{ fontSize: 20, marginRight: 5 }}
                >
                  location_on
                </i>
                <p>{binDetails.address?.split(" ").slice(0, 3).join(" ")}</p>{" "}
              </span>

              <p className="grey-text" style={{ fontSize: 12 }}>
                {binDetails.detailedAddress}.
              </p>

              <p className="grey-text capitalize " style={{ fontSize: 12 }}>
                {binDetails.material} bin
              </p>

              <div className="flex align-center">
                {binDetails.status === "active" && (
                  <div className="live-bin" style={{ marginRight: 5 }} />
                )}
                <p
                  className="  capitalize"
                  style={{
                    color: "green"
                  }}
                >
                  {/* {binDetails.status} */}
                  active
                </p>
              </div>
            </div>
          </div>

          <div className="flex align-end column ">
            <h5 className="cercle-purple-text">
              <span className="small-text grey-text">Metabin Number: </span>0
              {binDetails.number}
            </h5>
          </div>
        </div>

        <div className="mt-1">
          <div className=" full-width justify-end flex ">
            <p className="grey-text small-text ">
              {binDetails.fillLevel}% Filled
            </p>
          </div>
          <div className="full-width progress-line">
            <div className="progress-bg-line"></div>
            <div
              className="progress-bg-line2"
              style={{ width: `${binDetails.fillLevel}%` }}
            ></div>
          </div>
        </div>

        <div className="flex align-center space-between mt-1 ">
          <p>
            <span className="grey-text small-text ">
              Total Products Collected:
            </span>
            &nbsp;{binDetails.itemsCollected || 0}
          </p>
        </div>
      </div>

      <div className={isTablet ? "flex column gap-1 mv-1" : "flex gap-1 mt-1 "}>
        <div
          className="flex align-center full-width white box p-1   "
          style={{ width: !isTablet && "22vw" }}
        >
          <span className="material-symbols-outlined cercle-purple-text ">
            local_shipping
          </span>
          <div className="ml-1">
            <h5>Cumulative Waste Quantity Collected</h5>
            <h3 className="bold ">
              1.3 <span className="small-text">M.T</span>
            </h3>
            <p className="small-text grey-text ">
              Waste quantity collected from bin{" "}
            </p>
          </div>
        </div>

        <div
          className="flex align-center full-width white p-1 box"
          style={{ width: !isTablet && "22vw" }}
        >
          <span className="material-symbols-outlined cercle-purple-text ">
            barcode_scanner
          </span>
          <div className="ml-1">
            <h5>Rating Score</h5>
            <h3 className="big-text">
              {binDetails.rating}
              <span className="">/5</span>{" "}
            </h3>

            <p className="small-text grey-text ">
              Rating score based on user feedback
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BinDetails;
