import React from "react";
import { Table } from "react-materialize";
import { useNavigate } from "react-router-dom";

function UnActiveBins({ metaBinsList }) {
  const navigate = useNavigate();
  const inactive = metaBinsList.filter((item) => item.status === "inactive");

  // if (inactive.length === 0)
  //   return (
  //     <div className="white flex align-center justify-center">
  //       <p>All Your Metabins Are Active</p>
  //     </div>
  //   );

  return (
    <div className="white">
      <Table className="striped">
        <thead>
          <tr>
            <th className="primary-text">#</th>
            <th className="primary-text">Type</th>
            <th className="primary-text">Fullness</th>
            <th className="primary-text">Location</th>
            <th className="primary-text">Action</th>
          </tr>
        </thead>
        <tbody>
          {inactive.map((item, index) => {
            if (index <= 3)
              return (
                <tr className="small-text" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.material}</td>
                  <td>{item.fillLevel}%</td>
                  <td>{item.address}</td>
                  <td>
                    <span
                      onClick={() => navigate(`/bin-details/${item._id}`)}
                      className="material-symbols-outlined primary-text normal-size hover"
                    >
                      visibility
                    </span>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </Table>
      <div className="white flex align-center mt-4 justify-center column grey-text ">
      </div>
    </div>
  );
}

export default UnActiveBins;
