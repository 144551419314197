import React, { useEffect, useRef, lazy, Suspense } from "react";
import TopBinsCard from "../components/tables/TopBinsCard";
import SingleStateMap from "../components/maps/SingleStateMap";
import MainTable from "../components/MainTable";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  getNotificationsAction,
  getAnalyticsAction,
  getProfileAction,
  getMapDataAction
} from "../redux/action/MetaBinAction";
import WasteCollectionProgress from "../components/analytics/WasteCollectionProgress";
import DownloadApp from "../components/dashboard/DownloadApp";
import SustainabilityReports from "../components/dashboard/SustainabilityReports";
import { topBinsData } from "../topBinsMockData";
const LazyIndiaMap = lazy(() => import("../components/maps/IndiaMap"));
const LazyDashboardAnalytics = lazy(() =>
  import("../components/dashboard/DashboardAnalytics")
);
const LazyCollectionReport = lazy(() =>
  import("../components/dashboard/CollectionReport")
);
const LazyProductsCollected = lazy(() =>
  import("../components/analytics/ProductsCollected")
);
const LazySessionChart = lazy(() =>
  import("../components/dashboard/SessionChart")
);

function Dashboard() {
  const userRef = useRef();
  const dispatch = useDispatch();
  const [stateSelected, setStateSelected] = useState(null);

  const isTablet = useMediaQuery({ query: "(max-width: 1210px)" });
  const { analytics } = useSelector((state) => state.analyticsStore);
  useEffect(() => {
    dispatch(getNotificationsAction());
    dispatch(getAnalyticsAction());
    dispatch(getProfileAction());
    dispatch(getMapDataAction());

  }, [dispatch]);

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyDashboardAnalytics />
      </Suspense>

      <div
        className={isTablet ? "flex gap-1 mb-1 flex-wrap" : "flex gap-1 mb-1 mt-1 "}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyCollectionReport />
        </Suspense>

        <Suspense fallback={<div>Loading...</div>}>
          <SustainabilityReports />
        </Suspense>
      </div>

      <div className={isTablet ? "column flex  gap-1 mt-1" : "row flex"}>
        <div className={isTablet ? "col s12" : "col s8"}>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyIndiaMap
              setStateSelected={setStateSelected}
              stateSelected={stateSelected}
           
            />
          </Suspense> 
        </div>
        <div className={isTablet ? "col metabin-card s12" : "col s4  metabin-card  "}>
          <SingleStateMap stateSelected={stateSelected} />
        </div>
      </div>

      <div className="mv-1 col s12 mb-1 mt-1">
        <WasteCollectionProgress />
      </div>

      <div
        className={isTablet ? "column flex mv-1 gap-1 " : "  row"}
        style={{ height: isTablet ? "100%" : "380px" }}
      >
        <div
          className={isTablet ? "col s12" : "col s4 "}
          style={{ height: "100%" }}
        >
          <TopBinsCard topMetaBins={topBinsData} />
        </div>
        <div
          className={isTablet ? "col s12 " : "col s8 "}
          style={{ height: "100%" }}
        >
          <MainTable />
        </div>
      </div>

      <div
        className={isTablet ? "column flex mv-1 gap-1 " : "row"}
        ref={userRef}
      >
        <div className={isTablet ? "col s12" : "col s6"}>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyProductsCollected />
          </Suspense>
        </div>
        <div className={isTablet ? "col s12 " : "col s6 "}>
          <Suspense fallback={<div>Loading...</div>}>
            <LazySessionChart />
          </Suspense>
        </div>
      </div>
      <div className="flex column">
        <DownloadApp />
      </div>
    </div>
  );
}

export default Dashboard;
