import React, { useEffect } from "react";
import PickupHistoryTable from "../components/tables/PickupHistoryTable";
import BinDetails from "../components/singleBin/BinDetails";
import SingleBinChart from "../components/singleBin/SingleBinChart";
import { useDispatch, useSelector } from "react-redux";
import { getsingleMetaBinAction } from "../redux/action/MetaBinAction";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import UsersFeedBack from "../components/analytics/UsersFeedBack";
import { useNavigate } from "react-router-dom";
import CollectionReportChart from "../components/Charts/CollectionReportChart";
import { Button } from "react-materialize";
import MainTable from "../components/MainTable";

function SingleBinDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleBin, loading } = useSelector((state) => state.singleMetaBin);

  useEffect(() => {
    dispatch(getsingleMetaBinAction(id));
  }, [dispatch, id]);

  if (loading) return <Loading />;

  return (
    <>
      <header className="flex space-between">
        <div className="flex align-center  mb-2">
          <span
            className="material-symbols-outlined hover "
            onClick={() => navigate(-1)}
            role="button"
          >
            keyboard_backspace
          </span>
          <b className="normal-size ">&nbsp;MetaBin Details</b>
        </div>

        <div>
          <Button
            tooltip="Unlock the lid of this metabin"
            className="btn"
            disabled={true}
          >
            Unlock Metabin
          </Button>
          {/* <button className="grey btn">Disbale Live Feed</button> */}
        </div>
      </header>
      <div className="flex gap-2 flex-wrap mb-1">
        <BinDetails binDetails={singleBin} />

        <CollectionReportChart
          data={singleBin?.collectionReport ? singleBin?.collectionReport : []}
        />
      </div>
      <UsersFeedBack binId={id} />

      <div className="mb-1">
        <MainTable />
      </div>

      <SingleBinChart />
    </>
  );
}

export default SingleBinDetails;
