import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-materialize";

function TopBinsCard({ topMetaBins }) {
  const navigate = useNavigate();
  return (
    <Card style={{ height: "100%" }} className="metabin-card ">
      <div className="flex space-between mb-1 align-center  ">
        <b> Top Bins </b>
        <div className="btn-secondary flex align-center justify-center center">
          <p className="cercle-purple-text ">View All</p>
        </div>
      </div>
      {topMetaBins?.map((item, index) => {
    const [binIdentifier, ...restOfAddress] = item.address.split(','); // Splitting the address to get the bin identifier
    if (index < 6 && index > 0 )
        return (
            <div
                className="flex align-center space-between hover  mb-1"
                key={index}
                onClick={() => navigate(`/bin-details/${item._id}`)}
            >
                <div className="flex align-center ">
                    <img
                        src="../images/smart-bin.png"
                        style={{ width: "50px", height: "50px" }}
                        alt="smart-bin-image"
                    />
                    &nbsp;
                    <div>
                        <b className="bold cercle-purple-text ">{binIdentifier}</b> {/* Displaying the bin identifier */}
                        <p className="grey-text small-text">
                            {restOfAddress.join(', ')} {/* Joining the rest of the address back together */}
                        </p>
                    </div>
                </div>
                <p className="cercle-purple-text">{item.count} Bottles</p>
            </div>
        );
})}

    </Card>
  );
}

export default TopBinsCard;
