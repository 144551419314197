import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNotificationsAction } from "../../redux/action/MetaBinAction";

function Notifications() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notificationsStore);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, [dispatch]);
  return (
    <div className="notifications p-1 ">
      <b>Notifications</b>
      <p className="grey-text">
        You have ( {notifications.length + 0} ) notification
      </p>
      <hr />
      <div className="flex column center align-center full-width p-2">
        <span className="material-symbols-outlined grey-text" style={{ fontSize: "5rem" }}>
          notifications_active
        </span>
        <b className="grey-text small-text ">
          Hey! You have no any notifications
        </b>
      </div>
    </div>
  );
}

export default Notifications;
