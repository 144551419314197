import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-materialize";
import GeneralSettings from "../components/settings/GeneralSettings";
import ChangePassword from "../components/settings/ChangePassword";
import NotificationSettings from "../components/settings/NotificationSettings";
import EditProfile from "../components/settings/EditProfile";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getProfileAction } from "../redux/action/MetaBinAction";
import Loading from "./Loading";

function Settings() {
  const [editProfile, setEditProfile] = useState(false);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const { profile , loading  } = useSelector((state) => state.profileStore);

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <div className="container mt-2 metabin-card">
      <div className="flex space-between  p-1 align-center">
        <div className="flex align-center">
          <span
            className="material-symbols-outlined circle purple lighten-5 purple-text p-1"
            style={{ fontSize: "3rem" }}
          >
            person
          </span>

          <div className="ml-1">
            <p className="card-title capitalize ">{"Fade Najey"}</p>
            <p className="grey-text">+{"918807416965"}</p>
            <p className="grey-text">{"fade@cerclex.com"}</p>
          </div>
        </div>
        {!isTablet && (
          <img
            style={{ width: "0px", height: "0px" }}
          />
        )}
      </div>

      <Tabs>
        <Tab title="General">
          {" "}
          {editProfile ? (
            <EditProfile />
          ) : (
            <GeneralSettings editProfile={setEditProfile} />
          )}
        </Tab>
        <Tab title="Change Password">
          <ChangePassword />
        </Tab>
        <Tab title="Notification Settings">
          <NotificationSettings />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Settings;
