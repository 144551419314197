import React from "react";

function PaymentsHistory() {
  return (
    <div className="metabin-card p-1 full-width">
      <table className="striped">
        <thead>
          <tr>
            <th className="primary-text">Date</th>
            <th className="primary-text">Transactions</th>
            <th className="primary-text">Amount</th>
            <th className="primary-text">Status</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>21.06.2023</td>
            <td>Subscription Charges - new</td>
            <td>5.00</td>
            <td>
              <b className="successful-status">Completed</b>
            </td>
          </tr>

          <tr>
            <td>21.06.2023</td>
            <td>Subscription Charges - new</td>
            <td>3.00</td>
            <td>
              <b className="successful-status">Completed</b>
            </td>
          </tr>

          <tr>
            <td>21.06.2023</td>
            <td>Subscription Charges - new</td>
            <td>2.00</td>
            <td>
              <b className="successful-status">Completed</b>
            </td>
          </tr>

          <tr>
            <td>21.06.2023</td>
            <td>Subscription Charges - new</td>
            <td>10.00</td>
            <td>
              <b className="successful-status">Completed</b>
            </td>
          </tr>

          <tr>
            <td>21.06.2023</td>
            <td>Subscription Charges - new</td>
            <td>5.00</td>
            <td>
              <b className="successful-status">Completed</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PaymentsHistory;
