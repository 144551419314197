export const authenticationService = {
  baseURL: 'https://api.authentication.thinktrash.co',
};

export const planetWarriorService = {
  baseURL: 'https://staging.api.metabins.thinktrash.co',
};

export const metabin_management = {
  baseURL: 'https://staging.api.metabins-management.thinktrash.co',
};
;

