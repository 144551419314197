import React, { useState, useEffect } from "react";
import SingleMetaBin from "../components/dashboard/SingleMetaBin";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllMetaBinsAction } from "../redux/action/MetaBinAction";
import Loading from "./Loading";
import BinsAnalytics from "../components/singleBin/BinsAnalytics";
import { Pagination } from "react-materialize";
import bins from "../redux/localStore/allbins.json";

function Bins() {
  // const dispatch = useDispatch();
  // const { metaBinsList, loading } = useSelector((state) => state.allMetaBins);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectOption] = useState(null);
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setList(bins.slice(0, 50));
    const cities = [{ value: "all", label: "All metabins" }];
    bins.forEach((item) => {
      if (!item.location) return;
      if (
        cities.find(
          (city) =>
            city.value === item.location.city ||
            city.value === item.location.district
        )
      )
        return;

      cities.push(
        item.location.city
          ? {
              value: item.location.city,
              label: `${item.location.city}, ${item.location.state} `
            }
          : {
              value: item.location.district,
              label: `${item.location.district}, ${item.location.state} `
            }
      );
    });
    setOptions(cities);
    setSelectOption(cities[0]);
  }, []);

  // useEffect(() => {
  //   dispatch(getAllMetaBinsAction());
  // }, [dispatch]);

  // if (loading) return <Loading />;

  return (
    <>
      <h3 className="normal-size">Your Metabins</h3>
      {/* <BinsAnalytics metaBinsList={metaBinsList} /> */}

      <div className="container">
        <div
          className=" full-width p flex align-center space-between mb-2"
          id="metabins-list-header"
        >
          <div className="input-field-style">
            <i className="material-symbols-outlined">search</i>
            <input
              type="text"
              className="browser-default"
              placeholder="Search for Metabin"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);

                setList(
                  Bins.filter(
                    (item) =>
                      item.location?.city
                        ?.toLowerCase()
                        ?.includes(e.target.value?.toLocaleLowerCase()) ||
                      item.location?.district
                        ?.toLowerCase()
                        ?.includes(e.target.value?.toLocaleLowerCase()) ||
                      item.number === parseInt(e.target.value)
                  )
                );
              }}
            />
          </div>

          <div style={{ width: isTablet ? "35vw" : "15vw" }}>
            <Select
              value={selectedOption}
              onChange={(e) => {
                setSelectOption(e);
                if (e.value === "all") return setList(bins);

                setList(
                  bins.filter(
                    (item) =>
                      item.location.city === e.value ||
                      item.location.district === e.value
                  )
                );
              }}
              options={options}
              onFocus={{ borderColor: "red" }}
            />
          </div>
        </div>
        {list.map((item, index) => {
          return (
            <div className="mb-1" key={index}>
              <SingleMetaBin binDetails={item} />
            </div>
          );
        })}
      </div>

      <div className="flex align-center justify-center mt-1 ">
        <Pagination
          activePage={1}
          items={Math.round(Bins.length / 10)}
          leftBtn={<i className="material-symbols-outlined">chevron_left</i>}
          rightBtn={<i className="material-symbols-outlined">chevron_right</i>}
          maxButtons={10}
          onSelect={(e) => {
            const toSkip = e * 10;
            const newList = Bins.slice(toSkip, toSkip + 10);
            setList(newList);
            document.getElementById("metabins-list-header").scrollIntoView();
          }}
        />
      </div>
      <div className="full-width flex align-center justify-center">
      </div>
    </>
  );
}

export default Bins;
