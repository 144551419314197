import { notifyError } from "../../helpers/helper";
import { Auth, metaBinManagement } from "../config/configURL";

export const loginUser = (medium, password) => async (dispatch) => {
  dispatch({ type: "LOGGING_IN" });

  const obj = {
    medium: medium,
    password: password,
  };

  try {
    const { data: token } = await Auth.post("/account/login/step-1", obj);
    try {
      const config = { headers: { "x-authentication-token": token } };
      const { data } = await metaBinManagement.post(
        "/authenticate",
        {},
        config
      );

      dispatch({ type: "LOGGED_IN" });
      localStorage.setItem("__metabin", data);
      window.location.href = "/";
    } catch (ex) {
      notifyError(ex.response ? ex.response?.data : ex.message);
      dispatch({ type: "CANNOT_LOGIN" });
    }
  } catch (error) {
    dispatch({ type: "CANNOT_LOGIN" });
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const requestOtpAction = (phoneNumber, cb) => async (dispatch) => {
  dispatch({ type: "REQUESTING_OTP" });
  try {
    const { data } = await Auth.post(`/account/login/step-1?method=OTP`, {
      medium: phoneNumber,
    });

    dispatch({ type: "REQUESTED_OTP" });
    cb(data);
  } catch (error) {
    dispatch({ type: "CANNOT_REQUEST_OTP" });
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const confirmOTP = (phoneNumber, OTP, session) => async (dispatch) => {
  try {
    const { data: token } = await Auth.post("/account/login/step-2?method=OTP", {
      phoneNumber,
      sessionId: session,
      OTP,
    });
    try {
      const config = { headers: { "x-authentication-token": token } };
      const { data } = await metaBinManagement.post(
        "/authenticate",
        {},
        config
      );

      dispatch({ type: "LOGGED_IN" });
      localStorage.setItem("__metabin", data);
      window.location.href = "/";
    } catch (ex) {
      notifyError(ex.response ? ex.response?.data : ex.message);
      dispatch({ type: "CANNOT_LOGIN" });
    }
  } catch (error) {
    dispatch({ type: "CANNOT_LOGIN" });
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const logoutUserAction = () => async (dispatch) => {
  try {
    localStorage.removeItem("__metabin");
    window.location.href = "/login";
  } catch (error) {
    notifyError(error.response ? error.response?.data : error.message);
  }
};

