import {React, useState} from "react";
import { Tab, Tabs, Modal, Button } from "react-materialize";
import AccountsTable from "../components/accounts/AccountsTable";
import InvitationsTable from "../components/accounts/InvitationsTable";
import mockAccounts from "../mockAccounts";

function Accounts() {
  const [invitedEmail, setInvitedEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invitedEmail === "") {
      setErrorMessage(
        "Please Enter Email Address Of The Account You Want To Invite. "
      );
    } else {
      // Handle the invitation logic here if needed
    }
  };

  return (
    <div>
      <div className="flex align-center space-between mb-1 ">
        <b className="normal-size">Accounts Management</b>
        <Modal
          trigger={
            <Button
              className="btn-floating  waves-effect waves-light cercle-purple"
              tooltip="Invite Account"
            >
              <i className="material-symbols-outlined">person_add</i>
            </Button>
          }
        >
          <div>
            <form>
              <b>Invite New Account:</b>
              <p className="grey-text">
                Enter The Email Address Of The Account You Want To Invite
              </p>
              <input
                type={"email"}
                placeholder="eg. johndoe@company.com"
                className=" p-1 browser-default input-text "
                value={invitedEmail}
                onChange={(e) => setInvitedEmail(e.target.value)}
                onFocus={() => setErrorMessage("")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit(e);
                }}
              />
              {errorMessage && (
                <p className="small-text red-text">{errorMessage}</p>
              )}
              <div className="mt-2 flex align-center gap-1 justify-end ">
                

                <button className="btn cercle-purple " onClick={handleSubmit}>
                  Send Invitation
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <div className="metabin-card p-1 ">
        <Tabs>
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false
            }}
            title="Accounts"
            idx="TabID1fade"
          >
            <AccountsTable accounts={mockAccounts} />
          </Tab>
          {/* <Tab
            active
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false
            }}
            title="Invitations"
          >
            <InvitationsTable invitations={orgainzation.invitations} />
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
}

export default Accounts;
