import React from "react";
import { useMediaQuery } from "react-responsive";

function WasteCollectionProgress() {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div
      className={
        isTablet
          ? "mv-1 full-width white metabin-card p-1 "
          : "mv-1 full-width metabin-card p-1 metabin-cardwhite "
      }
    >
      <b className="normal-size">Your Current Milestone</b>
      <div className="recycled-waste">
        <b className="">
          Road To{" "}
          <span className="cercle-purple-text" style={{ fontSize: 22 }}>
            200
          </span>{" "}
          Tonnes Of Recycled Waste
        </b>
        <div className="line1">
          <div className="line2">
            <div className="progress-rate" style={{ width: 100 ? 71 + "%" : 0 }}>
              <p>{72?.toFixed(2)}%</p>
            </div>
            <img
              src="../images/car.svg"
              alt="car"
              style={{ width: "40px", height: "48px", marginLeft: "0px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WasteCollectionProgress;
