import React from "react";

function AddBalance() {
  return (
    <div>
      <b className="normal-size">Add Wallet Balance</b>

      <form>
        <p>Enter the Amount you want to add balance to Wallet:</p>
                     
        <div className="input-field">
        <span className="material-symbols-outlined primary">currency_rupee</span>
        <input
          className="browser-default input-text"
          placeholder=""
          type="number"
          onChange={(e) => {
            console.log(e.target.value);
          }}
        />
      </div>

      <div className="flex gap-1 justify-end " >
        
        <button className="btn" type="submit">Add Balance</button>
      </div>
      </form>
    </div>
  );
}

export default AddBalance;
