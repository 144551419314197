import React from "react";

function BillingDetails() {
  return (
    <div className="mt-2">
      <div className="metabin-card p-1">
        <span className="flex column mt-1">
          <b>Legal Business Name:</b>
          <b className="grey-text">Infinite Cercle Private Limited</b>
        </span>

        <span className="flex column mt-1">
          <b>Business Email</b>
          <b className="grey-text">tech@cerclex.com</b>
        </span>

        <span className="flex column mt-1">
          <b>Business Address</b>
          <p className="grey-text">
            Infinite Cercle Private Limited,, Rathinam Techzone, Eachanari,,
            coimbatore, Tamil Nadu 641021
          </p>
        </span>
      </div>
    </div>
  );
}

export default BillingDetails;
