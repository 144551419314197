import React from "react";
import Frame from "./layout/Frame";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Bins from "./pages/Bins";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Map from "./pages/Map";
import Help from "./pages/Help";
import Vouchers from "./pages/Vouchers";
import PickupTimeLine from "./pages/PickupTimeLine";
import ErrorPage from "./pages/ErrorPage";
import Accounts from "./pages/Accounts";
import FeedBack from "./pages/FeedBack";
import Analytics from "./pages/Analytics";
import Payments from "./pages/Payments";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages/LandingPage";
import SingaporeMap from "./pages/SingaporeMap";
import { toast } from "react-toastify";
import Login from "./pages/Login";

import SingleBinDetails from "./pages/SingleBinDetails";
import ConfirmOTP from "./pages/ConfirmOTP";

function Authenticated() {
  return (
    <Frame>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/map" element={<Map />} />
        <Route path="/bins" element={<Bins />} />
        <Route path="/map-test-sg" element={<SingaporeMap />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/confirm-OTP/:session" element={<ConfirmOTP />} />
        <Route path="/help" element={<Help />} />
        <Route path="/feedback/:binID" element={<FeedBack />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/vouchers" element={<Vouchers />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/pickup-time-line" element={<PickupTimeLine />} />
        <Route path="/bin-details/:id" element={<SingleBinDetails />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Frame>
  );
}

function UnAuthenticated() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

function App() {
  const userToken = localStorage.getItem("__metabin");

  window.addEventListener("offline", (e) => {
    toast.error("Please check your connection and try agin.");
  });

  return (
    <div>
      <BrowserRouter>
        {userToken ? <Authenticated /> : <UnAuthenticated />}
      </BrowserRouter>
    </div>
  );
}
export default App;
