const cities = [
  "Mumbai, Maharashtra",
  "Delhi",
  "Bangalore, Karnataka",
  "Hyderabad, Telangana",
  "Ahmedabad, Gujarat",
  "Chennai, Tamil Nadu",
  "Kolkata, West Bengal",
  "Surat, Gujarat",
  "Pune, Maharashtra",
  "Jaipur, Rajasthan"
];

function calculateFilledLevel(collectedProducts) {
  const itemsPerPercent = 30; 
  const percentIncrease = Math.floor(collectedProducts / itemsPerPercent) * 10;
  
  const filledLevel = Math.min(percentIncrease, 90);

  return filledLevel;
}

const generateBin = (id) => {
  const city = cities[Math.floor(Math.random() * cities.length)];
  const itemsCollected = Math.floor(Math.random() * 800);
  return {
    _id: `BIN${id}`,
    address: city.split(",")[0],
    detailedAddress: city,
    material: "plastic",
    status: "active",
    number: id,
    fillLevel: calculateFilledLevel(itemsCollected),
    itemsCollected: itemsCollected,
    rating: (Math.random() * 5).toFixed(1)
  };
};

const bins = Array.from({ length: 780 }, (_, i) => generateBin(i + 1));

export default bins;
