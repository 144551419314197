import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBinFeedBacAction } from "../redux/action/MetaBinAction";
import { formatDate } from "../helpers/helper";
import { useNavigate } from "react-router-dom";

function FeedBack() {
  const navigate = useNavigate();
  const { binID } = useParams();
  const dispatch = useDispatch();
  const { feedBack } = useSelector((state) => state.binFeedBack);

  useEffect(() => {
    dispatch(fetchBinFeedBacAction(binID));
  }, [dispatch]);

  return (
    <div>
      <span className="flex align-center ">
        <span
          className="material-symbols-outlined hover"
          onClick={() => navigate(-1)}
        >
          keyboard_backspace
        </span>
        <b className="normal-size">&nbsp;Single Bin FeedBack</b>
      </span>

      <div className="box white p-1 mt-1 ">
        <table className="striped">
          <thead>
            <tr>
              <th className="primary-text">No.</th>
              <th className="primary-text">Date</th>
              <th className="primary-text">Rating</th>
              <th className="primary-text">comment</th>
            </tr>
          </thead>

          <tbody>
          {feedBack.map((item, index) => {
            console.log(item.createdAt);

            if (index < 7)
              return (
                <tr className="semi-small-text" key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{formatDate(item.date)}</td>
                  <td>
                    {" "}
                    <div className="flex align-center gap-1 ">
                      <span className="flex">
                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 1 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 2 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 3 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 4 ? "gold" : "grey" }}
                        >
                          star
                        </span>

                        <span
                          className="material-symbols-outlined"
                          style={{ color: item.rating >= 5 ? "gold" : "grey" }}
                        >
                          star
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>{item.comment}</td>
                </tr>
              );
          })}
          </tbody>
        </table>
        {(feedBack.length == 0 || !feedBack) && (
          <div className="flex p-1 full-width column align-center justify-center mt-2 mb-2 ">
            <span className="material-symbols-outlined large grey-text ">
              comment
            </span>
            <p className="grey-text small-text">
              There is no feedBack right now.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeedBack;
