import React from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip
} from "recharts";

// Mock Data
const mockUserEngagement = {
  uniqueUsers: 3174,
  sessionsCount: 685,
  data: [
    { name: '18-24', count: 256, fill: '#8884d8' },
    { name: '25-29', count: 45, fill: '#83a6ed' },
    { name: '30-34', count: 133, fill: '#8dd1e1' },
    { name: '35-39', count: 92, fill: '#82ca9d' },
    { name: '40-49', count: 35, fill: '#a4de6c' },
    { name: '50+', count: 14, fill: '#d0ed57' },
  ]
};

function UserEngagement() {
  const style = {
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px"
  };

  return (
    <div className="metabin-card p-1 mv-1 flex column align-center ">
      <div className="flex align-center space-between mb-2 full-width ">
        <b>User Engagement</b>
        <p>
          For{" "}
          <b style={{ fontSize: 18 }} className="cercle-purple-text">
            {mockUserEngagement.uniqueUsers}
          </b>{" "}
          users and{" "}
          <b style={{ fontSize: 18 }} className="cercle-purple-text">
          </b>
           {mockUserEngagement.sessionsCount}
           {" "}
          sessions so far
        </p>
      </div>

      <div style={{ width: "100%", height: "440px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="50%"
            cy="50%"
            innerRadius="20%"
            outerRadius="80%"
            barSize={22}
            data={mockUserEngagement.data}
            barGap={15}
          >
            <RadialBar
              label={{ position: "insideStart", fill: "#fff" }}
              background
              clockWise
              style={{ width: "40px" }}
              dataKey="count"
              strokeWidth={1}
              cornerRadius={30}
              startAngle={180}
              endAngle={0}
            />
            <Tooltip />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
      <p className="grey-text small-text ">
        Track User Engagement and Identify Opportunities for Improvement.
      </p>
    </div>
  );
}

export default UserEngagement;

