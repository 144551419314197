const mockAccounts = [
    {
        account: {
            name: "Amit Sharma",
            email: "amit.sharma@gmail.com",
            phoneNumber: "9876543210"
        }
    },
    {
        account: {
            name: "Priya Singh",
            email: "priya.singh@gmail.com",
            phoneNumber: "9988776655"
        }
    },
    {
        account: {
            name: "Raj Malhotra",
            email: "raj.malhotra@gmail.com",
            phoneNumber: "7766554433"
        }
    },
    {
        account: {
            name: "Sunita Yadav",
            email: "sunita.yadav@gmail.com",
            phoneNumber: "8866552211"
        }
    },
    {
        account: {
            name: "Vikram Reddy",
            email: "vikram.reddy@gmail.com",
            phoneNumber: "9988112233"
        }
    },
    {
        account: {
            name: "Reena Roy",
            email: "reena.roy@gmail.com",
            phoneNumber: "8877445566"
        }
    },
    {
        account: {
            name: "Karan Kumar",
            email: "karan.kumar@gmail.com",
            phoneNumber: "7766889944"
        }
    },
    {
        account: {
            name: "Tina Desai",
            email: "tina.desai@gmail.com",
            phoneNumber: "8899776655"
        }
    },
    {
        account: {
            name: "Rohit Patel",
            email: "rohit.patel@gmail.com",
            phoneNumber: "9988664455"
        }
    },
    {
        account: {
            name: "Neha Gupta",
            email: "neha.gupta@gmail.com",
            phoneNumber: "8877554433"
        }
    }
];

export default mockAccounts;
