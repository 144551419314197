export const formatDate = (date) => {
  const createdAt = new Date(date);
  if (isNaN(createdAt)) {
      return "Invalid Date";
  }
  const formattedDate = createdAt.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return formattedDate;
};
