export const productsCollectedStatisticsData = [
  { date: "2023-09-01", totalProductsCollected: 622 },
  { date: "2023-09-02", totalProductsCollected: 810 },
  { date: "2023-09-03", totalProductsCollected: 350 },
  { date: "2023-09-04", totalProductsCollected: 560 },

  { date: "2023-09-05", totalProductsCollected: 222 },
  { date: "2023-09-06", totalProductsCollected: 610 },
  { date: "2023-09-07", totalProductsCollected: 350 },
  { date: "2023-09-08", totalProductsCollected: 560 },

  { date: "2023-09-09", totalProductsCollected: 422 },
  { date: "2023-09-10", totalProductsCollected: 910 },
  { date: "2023-09-11", totalProductsCollected: 450 },
  { date: "2023-09-12", totalProductsCollected: 560 },

  { date: "2023-09-13", totalProductsCollected: 422 },
  { date: "2023-09-14", totalProductsCollected: 310 },
  { date: "2023-09-15", totalProductsCollected: 250 },
  { date: "2023-09-16", totalProductsCollected: 860 },

  { date: "2023-09-17", totalProductsCollected: 222 },
  { date: "2023-09-18", totalProductsCollected: 910 },
  { date: "2023-09-19", totalProductsCollected: 450 },
  { date: "2023-09-20", totalProductsCollected: 510 },

  { date: "2023-09-21", totalProductsCollected: 422 },
  { date: "2023-09-22", totalProductsCollected: 610 },
  { date: "2023-09-23", totalProductsCollected: 750 },
  { date: "2023-09-24", totalProductsCollected: 360 },

  { date: "2023-09-26", totalProductsCollected: 222 },
  { date: "2023-09-27", totalProductsCollected: 810 },
  { date: "2023-09-28", totalProductsCollected: 650 },
  { date: "2023-09-29", totalProductsCollected: 960 },
  { date: "2023-09-30", totalProductsCollected: 1050 }
];

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const chartData = [
  { date: "2023-09-01", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-02", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-03", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-04", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-05", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-06", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-07", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-08", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-09", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-10", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-11", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-12", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-13", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-14", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-15", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-16", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-17", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-18", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-19", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-20", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-21", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-22", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-23", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-24", totalProductsCollected: getRandomInt(500, 1250) },

  { date: "2023-09-26", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-27", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-28", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-29", totalProductsCollected: getRandomInt(500, 1250) },
  { date: "2023-09-30", totalProductsCollected: getRandomInt(500, 1250) }
];
