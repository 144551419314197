import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import collectionReport from "../../redux/localStore/collectionReport.json";


const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];



const DonutChart = ({ }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!collectionReport) return;
    const total = collectionReport?.reduce((acc, item) => acc + item.count, 0);
    let mod = collectionReport?.map((item) => ({
      ...item,
      count: ((item.count / total) * 100).toFixed(2),
    }));
    mod = mod?.map((item) => ({
      ...item,
      count: parseFloat(item.count),
    }));

    setData(mod);
  }, [collectionReport]);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className="collection-report-chart p-1 box white">
      <div className="flex align-center space-between ">
        <b className="normal-size">Collection Report</b>
        <b className="grey-text">Since Last Pickup</b>
      </div>

      <ResponsiveContainer width="100%" height={260}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="productName"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            startAngle={-270}
            endAngle={-630}
            activeIndex={activeIndex}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value}%`} />
          <Legend formatter={(value) => `${value}`} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DonutChart;
