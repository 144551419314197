import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  YAxis
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getProductsCollectedByDateAction } from "../../redux/action/MetaBinAction";
import { useMediaQuery } from "react-responsive";
import { endOfMonth, startOfMonth } from "date-fns";
import { productsCollectedStatisticsData } from "../../productsCollectedStatisticsMockData";

function MainChart() {
  const isTablet = useMediaQuery({ query: "(max-width: 980px)" });

  const productsCollected = productsCollectedStatisticsData;

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  
  return (
    <div className="chart-dashboard">
      <div className="flex space-between align-center pl-1 pr-1 ">
        <div>
          <b className={isTablet && "small-text"}>
            Products Collected Statistics
          </b>
          <span>
            ({startDate?.toDateString()} - {endDate?.toDateString()})
          </span>
        </div>

        <div className=" chart-date-select flex align-center gap-1 ">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className="browser-default input-text "
            dateFormat="dd/MM/yyyy"
          />
          <span className="material-symbols-outlined cercle-purple-text ">
            calendar_month
          </span>
        </div>
      </div>

      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          width={400}
          height={450}
          data={productsCollected}
          margin={{
            top: 0,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="1" x2="0">
              <stop offset="20%" stopColor="#FF9100" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#FF507D" stopOpacity={1} />
            </linearGradient>
          </defs>

          <XAxis dataKey="date" />
          <YAxis dataKey="totalProductsCollected" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="totalProductsCollected"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MainChart;
