import React, { useState, useEffect } from "react";
import Map from "google-map-react";
import mapStyles from "../../layout/mapStyles";
import { mapKey } from "../../redux/config/configURL";

const GoogleMaps = ({ setOpenBinDetails, openBinDetails, metaBinsList }) => {
  const [defaultCoords, setDefaultCoords] = useState({
    lat: 22.3331514,
    lng: 75.9852781
  });

  const [iconSize, setIconSize] = useState({
    width: 80,
    height: 80
  });

  useEffect(() => {
    if (metaBinsList) {
      setDefaultCoords({
        lat: metaBinsList[0]?.location?.coordinates[1],
        lng: metaBinsList[0]?.location?.coordinates[0]
      });
    }
  }, [metaBinsList]);

  const AnyReactComponent = ({ text }) => (
    <div
      className="marker-box"
      onClick={() => {
        if (text && text._id && openBinDetails) {
          setOpenBinDetails({
            ...openBinDetails,
            open: !openBinDetails.open,
            binId: text._id
          });
        }
      }}
      key={text?._id}
    >
      <div className=" align-center justify-center flex  ">
        <img
          src="/images/bin_location.png"
          alt="bin location pin"
          style={iconSize}
        />
      </div>

      <div className="bin-status">
        <div>
          <b>Bin Status:</b>
          {text.status === "active" ? (
            <b
              className="capitalize green-text green lighten-5 box  "
              style={{ padding: "2px 8px " }}
            >
              {text.status}
            </b>
          ) : (
            <b
              className="capitalize red-text red lighten-5 box  "
              style={{ padding: "2px 8px " }}
            >
              {text.status}
            </b>
          )}
        </div>

        <div>
          <b>Fill Level:</b>
          <b>{text.fillLevel}%</b>
        </div>

        {/* <div className="flex align-center">
          <FaDotCircle
            style={{
              marginRight: 4,
              color: text.status === "active" ? "green" : "#ff0000",
            }}
          />

          <div className="flex column align-start">
            <p>{text.location?.city}</p>
            <p>{text.location?.state}</p>
          </div>
        </div>

        <div className="flex"></div>

        <div className="flex align-center justify-center">
          <h4>55%</h4>
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="row w-100">
      <div
        className="col text-center"
        style={{ width: "100%", height: "95vh" }}
      >
        <Map
          bootstrapURLKeys={{ key: mapKey }}
          defaultZoom={6}
          options={{ styles: mapStyles.darkeMap }}
          defaultCenter={defaultCoords}
          onZoomAnimationEnd={(e) => {
            let iconSize = (80 * e) / 6; // 80 is the default size of the icon
            setIconSize({
              width: iconSize,
              height: iconSize
            });
          }}
        >
          {metaBinsList.map((item, index) => {
            return (
              <AnyReactComponent
                lat={item.location?.coordinates[1]}
                lng={item.location?.coordinates[0]}
                text={item}
              />
            );
          })}
        </Map>
      </div>
    </div>
  );
};

export default GoogleMaps;
