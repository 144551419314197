import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { readMetaBinSessionsByDate } from "../../redux/action/MetaBinAction";
import { startOfMonth, endOfMonth } from "date-fns";
import { chartData } from "../../productsCollectedStatisticsMockData";

function SingleBinChart() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  

  const { id } = useParams();
  const { sesions } = useSelector(
    (state) => state.singleBinSessionStore
  );

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    dispatch(readMetaBinSessionsByDate(id, startDate, endDate));
  }, [dispatch, startDate, endDate, id]);

  return (
    <div className="chart-dashboard ">
      <div
        className={
          !isMobile
            ? "flex space-between align-center pl-1 pr-1 "
            : "flex space-between align-start pl-1 pr-1    "
        }
      >
        <div>
          <b className="semi-bold">Sessions Completed On Bin</b>
          { !isMobile && <span className="ml-1">
            ({new Date(startDate).toDateString()} -
            {new Date(endDate).toDateString()})
          </span>}
        </div>
        <div className="chart-date-select  flex align-center gap-1 ">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className=" browser-default input-text"
            dateFormat="dd/MM/yyyy"
          />
          <span className="material-symbols-outlined cercle-purple-text ">calendar_month</span>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="85%">
        <AreaChart
          width={400}
          height={500}
          data={chartData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 10
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="1" x2="0">
              <stop offset="20%" stopColor="#FF9100" stopOpacity={0.91} />
              <stop offset="80%" stopColor="#FF507D" stopOpacity={1} />
            </linearGradient>
          </defs>

          <XAxis dataKey="date" />
          <YAxis dataKey="totalProductsCollected" />
          <CartesianGrid strokeDasharray="3 31" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="totalProductsCollected"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SingleBinChart;
